import React from 'react'
import '../components/Popup.css'
import logo from '../assets/images/logo.png'
import slider1 from "../assets/images/slider/1.png"
import bg1 from "../assets/images/bg/1.png"

import img1 from "../assets/images/courses/img1.svg"
import img2 from "../assets/images/courses/img2.jpg"

import  img3 from "../assets/images/courses/img3.png"
import img4 from "../assets/images/courses/img4.png"
import img5 from "../assets/images/courses/img5.png"

import img6 from "../assets/images/courses/img6.png"
import img7 from "../assets/images/courses/img7.png"
import img8 from "../assets/images/courses/img8.png"

import blogimg1 from "../assets/images/blog/img-01.jpg"
import blogimg2 from "../assets/images/blog/img-02.jpg"
import blogimg3 from "../assets/images/blog/img-03.jpg"

import team1 from "../assets/images/team5.jpeg"
import team2 from "../assets/images/team6.jpeg"
import team3 from "../assets/images/team3.jpeg"
import team4 from "../assets/images/team4.jpeg"

import team5 from "../assets/images/team1.jpeg"
import team6 from "../assets/images/team2.jpeg"
import team7 from "../assets/images/team7.jpeg"
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'

import education from "../assets/images/news.png"
const Home = () => {
  return (
    <div>
     <Header/>
    <main>
        {/* <!-- ============Slider  Section  Start============ --> */}
        <section className="slider">
            <div className="shap"></div>
            <div className="shap-01">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width="75px" height="75px" viewBox="0 0 75 75" enable-background="new 0 0 75 75" >
                    <g id="Layer_1">
                    </g>
                    <g id="Layer_2">
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.0576" y1="37.5" x2="69.9424" y2="37.5">
                            <stop offset="0.35" style={{stopColor:"#EDF4F8"}} />
                            <stop offset="0.439" style={{stopColor:"#F6FAFC"}} />
                            <stop offset="0.5874" style={{stopColor:"#FDFEFE"}} />
                            <stop offset="1" style={{stopColor:"#FFFFFF"}} />
                        </linearGradient>
                        <polygon fill="url(#SVGID_1_)"
                            points="21.279,65.596 5.058,37.5 21.279,9.404 53.721,9.404 69.942,37.5 53.721,65.596 	" />
                    </g>
                </svg>
            </div>
            <div className="shap-02">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px"
                    y="0px" width="30px" height="200px" viewBox="0 0 30 200" 
                    >
                    <path style={{fill:"#FFFFFF"}} d="M20.666,176.646C20.666,186.782,18.129,195,15,195l0,0c-3.129,0-5.667-8.218-5.667-18.354V23.352
                	C9.333,13.217,11.871,5,15,5l0,0c3.129,0,5.666,8.217,5.666,18.352V176.646z" />
                </svg>
            </div>
            <div className="shape-03"></div>
            <div className="container">
                <div className="row">
                    <div className="main-wrapper">
                        <div className="sub-wrapper-01">
                            <div className="content">
                                <h1>Welcome to Dakshbharat Academy</h1>
                                <p>Welcome to Dakshbharat Academy, your premier destination for quality computer education. We are dedicated to providing the best learning experience for our students, empowering them with the knowledge and skills they need to excel in the digital world.</p>
                                <div className="btn-01">
                                    <ul>
                                        <li>
                                        <Link to="/contact">
                                        <a href="#">Contact Us</a>
                                        </Link>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="sub-wrapper-01">
                            <div className="image">
                                <div className="my-ba">
                                    <img src={slider1}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-01  Section  Start============ --> */}

        <section className='popup-cart'>
        <div >
          <div className='popup-header'>

            <div className='ph-box1'>
              <img src={logo} />
            </div>
            <div className='ph-box2'>
              <h3>दक्ष भारत</h3>
              <h6>कम्प्यूटर प्रशिक्षण एकेडमी</h6>
              <p>बाजार रोड, बन्दरचुआँ, जशपुर (छ.ग.)</p>
            </div>
          </div>
          <h6 className='popup-h4'>web: www.dakshaacademy.com</h6>

          <h5>दक्ष भारत के द्वारा प्रतियोगिता परीक्षा के संबंध में</h5>

          <p>1.  दक्ष भारत (कम्प्यूटर एकेडमी) के द्वारा जशपुर में पहली बार, जशपुर में अध्ययनरत अथवा जशपुर में अध्ययन करने के इच्छुक छात्र/छात्राओं के लिए प्रतियोगिता परीक्षा 2024 का आयोजन किया गया है।</p>
          
          <p>2. इस परीक्षा में हिम्दी, इतिहास, जनरल नॉलेज से संबंधित प्रश्न ही पूछे जायेंगे कि कुल 150 अंको के होंगे। प्रत्येक प्रश्न 1 अंक का होगा। समस्त प्रश्न वैकल्पिक होंगे तथा परिणाम पारदर्शी होगें।</p>

          <p>3. इस प्रतियोगिता परीक्षा में भाग लेने वाले समस्त छात्र / छात्राएं दक्ष एकेडमी / दक्ष भारत सदस्य कहलायेंगे, जिनको संस्था की तरफ से एजुकेशन कार्ड प्रदान किया जायेगा। </p>

          <p>4. रजिस्ट्रेशन DAKSH BHARAT APP द्वारा ONLINE तथा OFFLINE किया जा सकता है।</p>

          <h5>एजुकेशन कार्ड के लाभ-</h5>
          <p>+ डाटा एन्ट्री ऑपरेटर ट्रेनिंग फ्री 
          + टायपिंग ट्रेनिंग फ्री 
          + बेबसाईट / ऐप डिजाइनिंग कोर्स में 2000 रूपये की छूट 
          + बेसिक्स (ऑफिस वर्क) फ्री
          + स्टूडियो वर्क ट्रेनिंग फ्री 
          + कम्प्यूटर से संबंधित कोई भी कोर्स जैसे डी.सी.ए. बी.सी.ए., पी.जी.डी.सी., ग्राफिक्स डिजाइनिंग में 2000 (वो हजार रू.) की छूट (उक्त समस्त छूट एजूकेशन कार्ड धारी छात्र / छात्राओं को ही दी जायेंगी।)</p>
          <p>5. इस प्रतियोगिता में प्रथम स्थान से दसवें स्थान प्राप्त करने वाले छात्र/छात्राओं के नाम एवं प्रतिशत सामचार पत्र में प्रकाशित किये जायेंगे। इसके अलावा ईनाम की राशि व चमचमाती ट्राफी एक समारोह में विशिष्ट अतिथि के द्वारा मीडिया के समक्ष प्रदान की जावेगी।</p>

            <div className='popup-box1'>
              <div className='pb-box2child1'>
                <h5>पुरस्कार विवरण</h5>

              </div>
              <div className='pb-box2child2'>
              <button className='pb-btn1'>एंट्री फीस</button>
              <button className='pb-btn2'>शुल्क-199रु.</button>

              </div>
            </div>

        

            <table className='popup-table'>
            <tr>
              <th>परीक्षा में प्राप्ण स्थाय</th>
              <th>पुरसकार</th>
            </tr>

            <tr>
              <td>प्रथम स्थान</td>
              <td>101000 (एक लाख एक हजार रूपये का चेक) विजेता दो होंगे  (9th ,10th )और (11th , 12th ) दोनों में से एक एक </td>
            </tr>
            <tr>
              <td>द्रुतीय स्थान</td>
              <td>20000 (बीस हजार रुपये नगद)5 जी स्मार्ट फोन</td>
            </tr>
            <tr>
              <td>तृतीय</td>
              <td>10000 (दसहजार रुपये नगद) + 5 जी स्मार्ट फोन</td>
            </tr>

            <tr>
              <td colspan="2" style={{textAlign:"center",padding:"10px 0px",fontWeight:"bold"}}>परीक्षा में चौथा से दसवां स्थान प्राप्त करने वाले छात्र / छात्राओं को 2100 रुपये नगद पुरस्कार दिया जावेगा।</td>
              
            </tr>
            <tr>
              <td colspan="2" style={{textAlign:"center",padding:"10px 0px",fontWeight:"bold"}}> इस प्रतियोगिता परीक्षा में शामिल होने हेतु 8वीं पास होना अनिवार्य है।</td>
              
            </tr>
            </table>

            
            <p><b>विस्तृत जानकारी हेतु हमारे कार्यालय के पते पर अथवा मो.</b> नं. 7489396096, 9516109088, 7987609808</p>
            <Link to="/form" >
          <button className='popup-btn'>
         
          Enroll Now
          
          </button>
          </Link>
        </div>
        </section>

        <section className='education'>
            <h1 className='education-h1'>
                New Launch "Education Card"
            </h1>
           <div className="container">
           <img className='education-img' src={education}/>
           </div>
        </section>


        {/* <!-- ============bg-se-02  Section  Start============ --> */}

        <section className="bg-se-02">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>POPULAR COURSES</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img1}/>
                                </div>
                                <div className="sentence">
                                    <h3>DCA</h3>
                                    <p>Our DCA program is designed to provide a strong foundation in computer applications. The curriculum covers essential topics such as operating systems, databases, and software development, preparing students for various IT roles.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img2}/>
                                </div>
                                <div className="sentence">
                                    <h3>PGDCA</h3>
                                    <p>The PGDCA program offers advanced knowledge in computer applications. It includes in-depth training in programming languages, web development, and network management, aimed at graduates seeking to enhance their technical skills.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img3}/>
                                </div>
                                <div className="sentence">
                                    <h3>Tally</h3>
                                    <p>This course focuses on Tally, a popular accounting software. Students will learn how to manage financial accounts, handle taxation, and generate financial reports, making them proficient in accounting practices.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                   

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img4}/>
                                </div>
                                <div className="sentence">
                                    <h3>Basic</h3>
                                    <p>Our Basic Computer Course is perfect for beginners. It covers fundamental computer skills, including operating systems, word processing, spreadsheets, and internet usage, ensuring students are confident in using computers for everyday tasks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img5}/>
                                </div>
                                <div className="sentence">
                                    <h3>Typing</h3>
                                    <p>Our Typing course focuses on improving typing speed and accuracy. Students will practice touch typing techniques, learn to use typing software, and develop the skills necessary for efficient and error-free typing, which is essential for many administrative and clerical jobs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img6}/>
                                </div>
                                <div className="sentence">
                                    <h3>Photos studio job work</h3>
                                    <p>The Photo Studio course provides training in digital photography and photo editing. Students will learn techniques for capturing and editing images, using software like Adobe Photoshop to create stunning visuals.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img7}/>
                                </div>
                                <div className="sentence">
                                    <h3>Designing</h3>
                                    <p>This course is for aspiring designers. It covers graphic design principles, software tools such as Adobe Illustrator and CorelDRAW, and practical projects to build a strong portfolio.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img8}/>
                                </div>
                                <div className="sentence">
                                    <h3>Data entry</h3>
                                    <p>The Data Entry course trains students in accurate and efficient data input. It includes typing skills, data management, and using database software, preparing students for roles in data management and administration.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                  

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <i className="fal fa-life-ring"></i>
                                </div>
                                <div className="sentence">
                                    <h3>Operator</h3>
                                    <p>The Operator course is designed to train students in the essential skills required to operate and manage computer systems effectively. The course covers basic computer operations, troubleshooting common issues, and understanding the functionalities of different software applications. This course prepares students for roles in IT support and system administration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='gallery'>
            <div className="container">

                <div className="row">
                    <div className="heading">
                        <h2>Gallery</h2>
                    </div>
                </div>
                <div className=" d-flex justify-content-between align-items-center gap-3 flex-wrap">
                    <div className='gallery-img'>
                     <img src={team1} alt=''/>
                        
                    </div>
                    <div className='gallery-img '>
                     <img src={team2} alt=''/>
                        
                    </div>
                    <div className='gallery-img'>
                     <img src={team3} alt=''/>
                        
                    </div>
                    <div className='gallery-img'>
                     <img src={team4} alt=''/>
                        
                    </div>

                    <div className='gallery-img '>
                     <img src={team2} alt=''/>
                        
                    </div>
                    <div className='gallery-img'>
                     <img src={team3} alt=''/>
                        
                    </div>
                    <div className='gallery-img'>
                     <img src={team4} alt=''/>
                        
                    </div>
                </div>
                    
            </div>
        </section>


        <section className="bg-se-01">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>WHY CHOOS US</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-2 order-md-0 order-lg-0 order-xl-0">
                        <div className="main-card">
                            <div className="sub-card">
                                <img src={bg1}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="main-card">
                            <div className="content">
                                <ol>
                                    <li>
                                        <h3>Experienced Faculty</h3>
                                        <p>Our instructors are industry experts with years of experience. They bring practical knowledge and insights to the classNameroom, ensuring that our students receive top-quality education.</p>
                                    </li>
                                    <li>
                                        <h3>Affordable Fees</h3>
                                        <p>Quality education should be accessible to everyone. We offer competitive fees and various scholarship programs to ensure that financial constraints do not hinder your learning journey.</p>
                                    </li>
                                    <li>
                                        <h3>Positive Learning Environment</h3>
                                        <p>We foster a supportive and inclusive learning environment where students feel valued and encouraged. Our community is built on respect, collaboration, and a shared passion for technology.</p>
                                    </li>
                                    <li>
                                        <h3>Flexible Learning Options</h3>
                                        <p>We offer flexible learning options, including online and offline classNamees, to accommodate the diverse needs of our students. Whether you are a working professional or a full-time student, we have a schedule that fits your lifestyle.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        
        {/* <!-- ============bg-se-03  Section  Start============ --> */}

        <section className="bg-se-03">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>Featured Courses</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="wrapper">
                            <div className="owl-carousel owl-theme">
                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/1.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>Data Entry</b>
                                                <p>The Data Entry course trains students in accurate and efficient data input. It includes typing skills, data management, and using database software, preparing students for roles in data management and administration.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/2.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>Typing</b>
                                                <p>Our Typing course focuses on improving typing speed and accuracy. Students will practice touch typing techniques, learn to use typing software, and develop the skills necessary for efficient and error-free typing, which is essential for many administrative and clerical jobs.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/3.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>DCA</b>
                                                <p>Our DCA program is designed to provide a strong foundation in computer applications. The curriculum covers essential topics such as operating systems, databases, and software development, preparing students for various IT roles.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/4.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>Designing</b>
                                                <p>This course is for aspiring designers. It covers graphic design principles, software tools such as Adobe Illustrator and CorelDRAW, and practical projects to build a strong portfolio.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/5.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>Photos studio job work</b>
                                                <p>The Photo Studio course provides training in digital photography and photo editing. Students will learn techniques for capturing and editing images, using software like Adobe Photoshop to create stunning visuals.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/6.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>PGDCA</b>
                                                <p>The PGDCA program offers advanced knowledge in computer applications. It includes in-depth training in programming languages, web development, and network management, aimed at graduates seeking to enhance their technical skills.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="my-items">
                                        <div className="content">
                                            <div className="image-01">
                                                <img src="assets/images/courses/7.jpg"/>
                                            </div>
                                            <div className="sentence">
                                                <b>Tally</b>
                                                <p>This course focuses on Tally, a popular accounting software. Students will learn how to manage financial accounts, handle taxation, and generate financial reports, making them proficient in accounting practices.</p>
                                                <ol>
                                                    <li>3 Year Course</li>
                                                    <li>100 Clasess</li>
                                                    <li>10 am - 1 pm</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-04  Section  Start============ --> */}

        <section className="bg-se-04">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="_lk_bg_cd">
                            <i className="fal fa-history"></i>
                            <div className="counting" data-count="20">11</div>
                            <h5>YEAR OF HISTORY</h5>
                        </div>
                    </div>
        
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="_lk_bg_cd">
                            <i className="fal fa-graduation-cap"></i>
                            <div className="counting" data-count="80">150</div>
                            <h5>ACTIVE STUDENTS</h5>
                        </div>
                    </div>
        
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="_lk_bg_cd">
                            <i className="fal fa-globe"></i>
                            <div className="counting" data-count="27">10</div>
                            <h5>ONLINE COURSES</h5>
                        </div>
                    </div>
        
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="_lk_bg_cd">
                            <i className="fal fa-registered"></i>
                            <div className="counting" data-count="62">200</div>
                            <h5>REGISTERED STUDENTS</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-05  Section  Start============ --> */}

        <section className="bg-se-05">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>Latest News</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg1}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3> The Advantages of Learning from Experienced Faculty</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">August 20, 2023</span></li>
                                    </ol>
                                    <p>When it comes to quality education, the expertise and experience of the faculty play a crucial role. At Dakshbharat Academy, we pride ourselves on having a team of highly experienced and knowledgeable instructors who bring a wealth of practical insights to the classroom.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg2}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3>How Comprehensive Curriculum Prepares You for the Future</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">April 10, 2024 </span></li>
                                    </ol>
                                    <p>In today's fast-paced world, having a comprehensive education is essential for staying competitive in the job market. At Dakshbharat Academy, we offer a wide range of courses designed to equip you with the knowledge and skills needed to succeed in your career.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg3}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3>The Benefits of Hands-on Training and Practical Experience</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">June 25, 2024</span></li>
                                    </ol>
                                    <p>In the world of computer education, hands-on training and practical experience are crucial for developing the skills needed to succeed in the industry. At Dakshbharat Academy, we emphasize practical, hands-on learning to ensure our students are well-prepared for their careers.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>

       
    </main>

 <Footer/>
    </div>
  )
}

export default Home
