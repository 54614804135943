import React from 'react'
import './Form.css'

import scanner from '../assets/images/form/scanner.jpeg'
const Form = () => {
  return (
  
<div id="box1">
    <form action='https://script.google.com/macros/s/AKfycbxN56reS_K1kTjyx2p9ygf6T3xByaVU6imt6_lHmNqF48jJNSm9G_ZbKonC8oSGw_h4/exec' method='post'>
    <h1 class='form-h1'>Daksh Academy</h1>
    <p>Required Fileds Followed by</p>
    <h2>Enroll Informetion</h2>

    <div class="form-scanner">
   <div className='form-scanner-1'>
<img src={scanner} alt=""/>
   </div>
   <h3>Payment of Enroll Fees (RS. 199.00/)</h3>

    </div>
    <label for="1">Name</label>
    *<input type="text"
    name='Name'
    placeholder="Name"id="1"required />
    <br/><br/>

    <label for="1">Father Name</label>
    *<input type="text"
    name='FatherName'
    placeholder="Father name"id="1"required />
    <br/><br/>
    <label for="1">Adhar Number</label>
    *<input type="number"
    name='AdharNumber'
    placeholder="Adhar Number"id="1"required />
    <br/><br/>
    <label for="1">Age</label>

    *<input type="number"
    name='Age'
    placeholder="Age"id="1"required />
    <br/><br/>

    <label for="1">Date of Birth</label>
    *<input type="date"
    name='DateofBirth'
    placeholder="Date of Birth"id="1"required />
    <br/><br/>

    <label for="1">Mubile Number</label>

    *<input type="number"
    name='MubileNumber'
    placeholder="Mobile Number"id="1"required />
    <br/><br/>
   


    <label for="1">Class (9th ,10th , 11th and 12th Only)</label>
    <input type="text"id="3"
    name='Class'
    placeholder="class "/>

   <br/>
   <p>FullAddress<textarea Name="FullAddress" 
   cols="70" rows="7"placeholder="Full Address">
   </textarea></p>
  
    <label for="3">Pin cord</label>
    <input type="password"id="3"
    name='Pincord'
    placeholder="password"/>
   
   <input type="submit"value="Submit" class='submitButton'/>
   
   </form>
  
</div>
  )
}

export default Form
