import React from 'react'

const Footer = () => {
  return (
    <div>
       {/* <!-- ============Footer  Start============ --> */}

       <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-3 col-6">
                    <div className="_kl_de_w">
                        <h3>Smart Group</h3>
                        <p>We offer scholarships and financial aid for eligible students. Contact our admissions office for more information.</p>
                    </div>
                </div>
    
                <div className="col-lg-3 col-md-3 col-6">
                    <div className="_kl_de_w">
                        <h3>Quick Links</h3>
                        <ol>
                            <li><i className="far fa-angle-right"></i>home</li>
                            <li><i className="far fa-angle-right"></i>About Us</li>
                            <li><i className="far fa-angle-right"></i>Services</li>
                            <li><i className="far fa-angle-right"></i>Blog</li>
                            <li className="last"><i className="far fa-angle-right"></i>Contact Us</li>
                        </ol>
                    </div>
                </div>
    
                <div className="col-lg-3 col-md-3 col-6">
                    <div className="_kl_de_w">
                        <h3>Featured Courses</h3>
                        <ol>
                            <li><i className="far fa-angle-right"></i>Designing</li>
                            <li><i className="far fa-angle-right"></i>Tally</li>
                            <li><i className="far fa-angle-right"></i>PGDCA</li>
                            <li><i className="far fa-angle-right"></i>DCA</li>
                            <li className="last"><i className="far fa-angle-right"></i>Typing</li>
                        </ol>
                    </div>
                </div>
    
                <div className="col-lg-3 col-md-3 col-6">
                    <div className="_kl_de_w">
                        <h3>Contact Us</h3>
                        <form className="my-form">
                            <div className="form-group">
                                <input className="form-control" type="emal" name="email" placeholder="Email"/>
                            </div>
                            <div className="form-group">
                                <textarea rows="3" placeholder="Message" className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                                <a href="#">Submit</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <div className="end-footer">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="last-card">
                        <p>© 2024 All Rights Reserved </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </div>
  )
}

export default Footer
