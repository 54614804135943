import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import './assets/css/style.css';
// import './assets/scss/style.scss';
import './assets/css/bootstrap.min.css';
import './assets/css/plugins/owl.carousel.css';
import './assets/css/plugins/owl.carousel.min.css';
import './assets/css/all.min.css';


import Form from './pages/Form';

import { BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';

function App() {
  return (
 <>

 <Router>
  <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/form" element={<Form/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/services" element={<Services/>}/>
    <Route path="/blog" element={<Blog/>}/>
    <Route path="/contact" element={<Contact/>}/>
  </Routes>
 </Router>
  
 
 </>
  );
}

export default App;
